/* ==========================================================================
   Expandable textarea
   ========================================================================== */

var ExpandableTextarea = (function() {
	var $textarea = $('[data-expandable-textarea]');

	var init = function() {
		$textarea.each(function () {
			$(this).on('keyup', _keydown);
			$(this).trigger('keyup');
		});

	};

	var _keydown = function () {
		var el = this;
		setTimeout(function(){
			var height = el.scrollHeight;
			el.style.cssText = 'height:' + height + 'px';
		}, 1);
	};

	return {
		init: init
	};
})();

$(document).ready(ExpandableTextarea.init);





