/* ==========================================================================
   Switch position fixed element
   ========================================================================== */

var SwitchPositionFixedElement = (function() {
	var $waypointModule = $('[data-target="private-clients"]'),
		$footer = $('.o-page-footer');

	var _isTop = function($el) {
		return ($el.offset().top >= $(window).scrollTop());
	};

	var _setFooterZIndex = function(zIndex) {
		$footer.css({ 'z-index' : zIndex, });	
	};

	var _toggleFooterZIndex = function() {
		if(_isTop($waypointModule)) {
			_setFooterZIndex(-100);
		} else {
			_setFooterZIndex(100);
		}
	};

	var init = function() {
		_toggleFooterZIndex();
		
		$(window).scroll(function() {
			_toggleFooterZIndex();
		});
	};

	return {
		init: init
	};
})();

$(document).ready(SwitchPositionFixedElement.init);