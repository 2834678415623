/* ==========================================================================
   Scroll to next section
   ========================================================================== */

var ScrollToNextSection = (function() {
	'use strict';

	var $triggerElement = $('[data-scroll-next-section]');

	var init = function() {
		$triggerElement.on('click', _scroll);
	};

	var _scroll = function(e) {
		e.preventDefault();

		var $thisSection = $(this).closest('main').children().has($(this));
		var sectionMargin = parseInt($thisSection.css('marginBottom'));

		$('html, body').animate({ scrollTop: $thisSection.next().offset().top - sectionMargin }, 'slow');
	};

	return {
		init: init
	};
})();

$(document).ready(ScrollToNextSection.init);