/* ==========================================================================
   Set full height on touch devices
   ========================================================================== */

var FullHeightMobile = (function() {
	var $element = $('.o-page-module--full-height');

	var init = function() {
		if($element.length && Modernizr.touchevents) {
			_setHeight();

	        $(window).bind('orientationchange', function(event) {
	            _setHeight();
	        });			
		}
	};

	var _setHeight = function() {
		$element.attr('style', ' min-height: ' + $(window).height() + 'px !important');
	};

	return {
		init: init
	};
})();

$(document).ready(FullHeightMobile.init);