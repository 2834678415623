/* ==========================================================================
   Module
   ========================================================================== */

var Module = (function() {
	var element = {};

	var init = function() {

	};

	return {
		init: init
	};
})();

$(document).ready(Module.init);