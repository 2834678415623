/* ==========================================================================
   Overlay
   ========================================================================== */

var Overlay = (function() {
	var element = {
		overlays: '[data-overlay]',
		trigger: '[data-toggle]',
		close: '[data-close]'
	};

	var init = function() {
		$(element.trigger).on('click', _toggle);
		$(element.close).on('click', _close);

		$(document).on('overlay:close-all', _close);

		$(document).keyup(function(event) {
			if (event.keyCode == 27) {
				$(document).trigger('overlay:close-all');
			}
		});
	};

	var _toggle = function(event) {
		event.preventDefault();

		var overlay = $(this).attr('data-toggle'),
			$overlay = $('[data-overlay="' + overlay + '"]');

		$overlay.toggleClass('open');
		$('html').toggleClass('overlay-open', $overlay.hasClass('open'));
		if (overlay == 'navigation') {
			$('html').toggleClass('navigation');
		}
	};

	var _close = function(event) {
		event.preventDefault();

		$(element.overlays).removeClass('open');
		$('html').removeClass('overlay-open navigation');
	};

	return {
		init: init
	};
})();

$(document).ready(Overlay.init);