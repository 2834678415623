/* ==========================================================================
   Footer reveal
   ========================================================================== */

var FooterReveal = (function() {
	var $footer = $('.o-page-footer'),
        $prev = $footer.prev();

	var init = function() {
		// Don't execute if footer is larger than screen
		if ($footer.outerHeight() <= $(window).outerHeight()) {
			$footer.css({
				'z-index' : -100,
				position : 'fixed',
				bottom : 0
			});

			$(window).on('load resize', debounce(function() {
				$footer.css({ 'width' : $prev.outerWidth() });
				$prev.css({ 'margin-bottom' : $footer.outerHeight() - 1 });
			}, 100));
		}
	};

	return {
		init: init
	};
})();

$(document).ready(FooterReveal.init);