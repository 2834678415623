/* ==========================================================================
   ScrollTo
   ========================================================================== */

var ScrollTo = (function() {
	var element = {
		to: '[data-scroll-to]'
	};

	var init = function() {
		$(element.to).on('click', _to);
	};

	var _to = function(event) {
		event.preventDefault();
		var $el = $('[data-target="' + $(this).attr('data-scroll-to') + '"]');

		// Fixes issues with sticky footer
		var style = ($el.attr('style')) ? $el.attr('style') :'';
		$el.css('position', 'relative');
		var offset = $el.offset().top;
		$el.attr('style', style);

		_scroll(offset);
	};

	var _scroll = function(offset) {
		if(! $('.overlay.open').length) {
			$('html, body').animate({
				scrollTop: offset
			}, 800);
		}
		else {
			$('html, body').animate({
				scrollTop: offset
			}, 1, function(){
				$(document).trigger('overlay:close-all');
			});
		}
	};

	return {
		init: init
	};
})();

$(document).ready(ScrollTo.init);