jQuery(document).ready(function($) {

	// viewportunit polyfill
	viewportUnitsBuggyfill.init({
		hacks: window.viewportUnitsBuggyfillHacks
	});

	// svg all the things
	svg4everybody();

	// global vars
	$doc = $(document),
	$win = $(window),
	$html = $('html'),
	$body = $('body'),
	$htmlBody = $('html, body'),
	breakpoint = {
		'x-small':   350,
		'small':     600,
		'medium':    800,
		'large':     1000,
		'x-large':   1400,
		'xx-large':  1800
	};

});