/* ==========================================================================
   Contact form
   ========================================================================== */

var ContactForm = (function() {
	var form = {
		element: '[data-form]',
		message: '[data-message]'
	};

	var init = function() {
		$(form.element).ajaxForm({ 
			target: form.message, 
			resetForm: true,
			success: function() { 
				$(this).clearForm();
			} 
		}); 
	};

	return {
		init: init
	};
})();

$(document).ready(ContactForm.init);